import { Box, keyframes } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'

const animationScroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

interface CustomCarouselProps {
  cards: JSX.Element[]
}

const defaultCardsPerView = 3
const gap = 16 // Equivalent to Tailwind's gap-4 (4 * 4px)

export default function CustomCarousel(props: CustomCarouselProps) {
  const { cards } = props
  const mainDivRef = useRef<HTMLDivElement>(null)
  const [mainDivWidth, setMainDivWidth] = useState(0)
  const [mouseOvered, setMouseOvered] = useState(false)
  const [cardsPerView, setCardPerView] = useState(defaultCardsPerView) // Number of cards visible at a time

  const cardWidth = useMemo(() => {
    return (mainDivWidth - gap * (cardsPerView - 1)) / cardsPerView
  }, [cardsPerView, mainDivWidth])

  // Dynamically calculate the animation duration based on the scrollable width
  const animationDuration = useMemo(() => {
    if (cardsPerView === 1) return '15s'
    else if (cardsPerView === 2) return '17.5s'
    else return '20s'
  }, [cardsPerView])

  useEffect(() => {
    const observer = new ResizeObserver((e) => {
      e.forEach((entry) => {
        if (entry.target === mainDivRef.current) {
          setMainDivWidth(entry.contentRect.width)
        }
      })
    })
    if (mainDivRef.current) observer.observe(mainDivRef.current)
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (mainDivWidth < 600) {
      setCardPerView(1)
    } else if (mainDivWidth < 950) {
      setCardPerView(2)
    } else {
      setCardPerView(defaultCardsPerView)
    }
  }, [mainDivWidth])

  return (
    <Box
      ref={mainDivRef}
      className="overflow-hidden w-full py-10"
      sx={{
        '& .animate-scroll': {
          animation: `${animationScroll} ${animationDuration} linear infinite`,
          animationPlayState: mouseOvered ? 'paused' : 'running'
        }
      }}
      onMouseEnter={() => setMouseOvered(true)}
      onMouseLeave={() => setMouseOvered(false)}>
      <div className="relative flex w-full">
        <div className="flex animate-scroll">
          {cards.concat(cards).map((element, index) => (
            <div
              key={index}
              style={{
                minWidth: `${cardWidth}px`,
                maxWidth: `${cardWidth}px`,
                marginRight: `${gap}px`
              }}>
              {element}
            </div>
          ))}
        </div>
      </div>
    </Box>
  )
}
